import React from 'react'
// nodejs library that concatenates classes
// import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
// core components
// import GridContainer from 'components/Grid/GridContainer.js'
// import GridItem from 'components/Grid/GridItem.js'
// @material-ui/core components
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useMediaQuery, Box, Grid } from '@material-ui/core'

import Milestone from 'assets/img/myImg/common/milestone.jpg'
import office1 from 'assets/img/myImg/common/office1.jpg'
import office2 from 'assets/img/myImg/common/office2.jpg'
import officeStyle from 'assets/jss/material-kit-pro-react/views/aboutUsSections/officeStyle.js'

const useStyles = makeStyles(officeStyle)
// const train1 = 'https://i.ibb.co/dJRFRLV/Athena-Training-2018-200913.jpg'
// const train2 = 'https://i.ibb.co/tqWZkKk/Athena-Training-2018.jpg'
// const train3 = 'https://i.ibb.co/vBQJvp8/Training-2019-200913-1.jpg'

// const train4 = 'https://i.ibb.co/6wcrc8w/Picture2.jpg'
// const train5 = 'https://i.ibb.co/GC1gcsJ/Picture3.jpg'
// const train6 = 'https://i.ibb.co/fFgZ414/Picture1.jpg'

export default function SectionOffice() {
  const classes = useStyles()
  const theme = useTheme()
  const { t } = useTranslation('AboutUsPage')
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <div className={classes.office}>
      <img src={Milestone} alt="Milestone" width="100%" />
      <div style={{ padding: '40px 0' }}>
        <h3 className={classes.title}>{`2022 ${t('office')}`}</h3>
        <Grid container alignItems="center">
          <Grid item xs={12} md={4}>
            <h1 className={classes.officeTitle}>
              <span style={{ color: '#366ed8' }}>New</span> Office
            </h1>
          </Grid>
          <Grid item xs={12} md={8}>
            <img src={office1} alt={'new-office'} className={classes.office1} />
          </Grid>
        </Grid>
        {smDown && <Box height="20px" />}
        <Grid container alignItems="center">
          <Grid item xs={12} md={4}>
            <Box
              display="flex"
              flexDirection="column"
              className={classes.office2}
            >
              <img src={office2} alt={'new-office'} style={{ margin: '0' }} />
              <p style={{ textAlign: 'right' }}>Available On Early 2022</p>
            </Box>
          </Grid>
          <Grid item xs={12} md={8}>
            <Box width="320px" margin="0 auto">
              <h3 style={{ fontWeight: 700 }}>New office will include:</h3>
              <ul>
                <li>At least 2,000 SQM warehouse</li>
                <li>At least 300 SQM cooling storage</li>
                <li>
                  3 professtional laboratories - Cosmetics (Powder & Skin care)
                  and Food
                </li>
                <li>Seminar & training center</li>
                <li>Testing laboratory and facility</li>
              </ul>
            </Box>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

/* <CustomFade direction="up">
  <GridContainer className={classes.textCenter}>
    <GridItem
      md={8}
      sm={8}
      className={classNames(classes.mrAuto, classes.mlAuto)}
    >
      <h2 className={classes.title}>
        {t('training.title')}
      </h2>
      <h4 className={classes.description}>{t('training')}</h4>
    </GridItem>
  </GridContainer>
  <GridContainer>
    <GridItem md={4} sm={4}>
      <img
        className={classNames(
          classes.imgRaised,
          classes.imgFluid,
          classes.rounded
        )}
        src={train1}
        alt="train1"
      />
    </GridItem>
    <GridItem md={4} sm={4}>
      <img
        className={classNames(
          classes.imgRaised,
          classes.imgFluid,
          classes.rounded
        )}
        src={train2}
        alt="train2"
      />
    </GridItem>
    <GridItem md={4} sm={4}>
      <img
        className={classNames(
          classes.imgRaised,
          classes.imgFluid,
          classes.rounded
        )}
        src={train3}
        alt="train3"
      />
    </GridItem>
    <GridItem md={4} sm={4}>
      <img
        className={classNames(
          classes.imgRaised,
          classes.imgFluid,
          classes.rounded
        )}
        src={train4}
        alt="train4"
      />
    </GridItem>
    <GridItem md={4} sm={4}>
      <img
        className={classNames(
          classes.imgRaised,
          classes.imgFluid,
          classes.rounded
        )}
        src={train5}
        alt="train5"
      />
    </GridItem>
    <GridItem md={4} sm={4}>
      <img
        className={classNames(
          classes.imgRaised,
          classes.imgFluid,
          classes.rounded
        )}
        src={train6}
        alt="train6"
      />
    </GridItem>
  </GridContainer>
</CustomFade> */

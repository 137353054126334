import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import SearchIcon from '@material-ui/icons/Search'
import ClearIcon from '@material-ui/icons/Clear'
import { InputAdornment, Box, TextField, IconButton } from '@material-ui/core'

// core components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import ProductCollapseCard from 'components/ProductCollapseCard'
import sectionSimilarStoriesStyle from 'assets/jss/material-kit-pro-react/views/blogPostSections/sectionSimilarStoriesStyle.js'
import { products } from 'data/products'

const useStyles = makeStyles(sectionSimilarStoriesStyle)
const SectionProducts = () => {
  const classes = useStyles()
  const [searchStr, setSearchStr] = useState('')
  const { t } = useTranslation(['products', 'component'])

  const handleChange = (e) => {
    const value = e.target.value
    setSearchStr(value)
  }

  const filtered = products.filter((product) => {
    const { items, title } = product
    const concatItems = items.map((item) => item.name.toLowerCase())
    concatItems.unshift(title.toLowerCase())
    if (searchStr) {
      return concatItems.some((item) => item.includes(searchStr.toLowerCase()))
    }
    return true
  })

  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer>
          <GridItem md={12}>
            <h3 className={classes.title + ' ' + classes.textCenter}>
              {t('product.title')}
            </h3>
            <h5 className={classes.description + ' ' + classes.textCenter}>
              {t('product.description')}
            </h5>
            <Box my={6}>
              <TextField
                value={searchStr}
                placeholder="Search Product Here..."
                variant="outlined"
                onChange={handleChange}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      {searchStr && (
                        <IconButton size="small" onClick={() => setSearchStr("")}>
                          <ClearIcon />
                        </IconButton>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box p={1} minHeight="50vh">
              <GridContainer spacing={2}>
                {filtered.map((el) => (
                  <GridItem xs={12} md={6} key={el.title}>
                    <ProductCollapseCard
                      title={el.title}
                      icon={el.icon}
                      items={el.items}
                    />
                  </GridItem>
                ))}
              </GridContainer>
            </Box>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  )
}

export default SectionProducts

import React from 'react'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
// core components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import CardHeader from 'components/Card/CardHeader.js'
import Info from 'components/Typography/Info.js'
import CustomFade from 'components/CustomFade'

import blogsStyle from 'assets/jss/material-kit-pro-react/views/sectionsSections/blogsStyle.js'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(blogsStyle)

const SectionHistory = () => {
  const classes = useStyles()
  const {t} = useTranslation('AboutUsPage')
  return (
    <div className={classes.blog}>
      <div className={classes.container}>
        <GridContainer>
          <GridItem
            xs={12}
            sm={8}
            md={8}
            className={classes.mlAuto + ' ' + classes.mrAuto}
          >
            <h2 className={classes.title}>{t('history')}</h2>
            <br />
            <CustomFade direction="up">
              <Card plain blog className={classes.card4}>
                <CardHeader image plain>
                  <a href="#pablito" onClick={(e) => e.preventDefault()}>
                    <img
                      src={'https://i.ibb.co/0mmtXnQ/history1.png'}
                      alt="..."
                    />
                  </a>
                  <div
                    className={classes.coloredShadow}
                    style={{
                      backgroundImage: `url(https://i.ibb.co/0mmtXnQ/history1.png)`,
                      opacity: '1',
                    }}
                  />
                </CardHeader>
                <CardBody plain>
                  <Info>
                    <h6 className={classes.cardCategory}>2010-2012</h6>
                  </Info>
                  <h3 className={classes.cardTitle}>
                    {t('history.2010.title')}
                  </h3>
                  <h5 className={classes.description}>
                    {t('history.2010.content')}
                  </h5>
                </CardBody>
              </Card>
            </CustomFade>
            <CustomFade direction="up">
              <Card plain blog className={classes.card4}>
                <CardHeader image plain>
                  <img
                    src={
                      'https://i.ibb.co/1rHwKds/Screenshot-2020-10-25-114110.png'
                    }
                    alt="..."
                  />
                  <div
                    className={classes.coloredShadow}
                    style={{
                      backgroundImage: `url(https://i.ibb.co/1rHwKds/Screenshot-2020-10-25-114110.png)`,
                      opacity: '1',
                    }}
                  />
                </CardHeader>
                <CardBody plain>
                  <Info>
                    <h6 className={classes.cardCategory}>2015</h6>
                  </Info>
                  <h3 className={classes.cardTitle}>{t('history.2015.title')}</h3>
                  <h5 className={classes.description}>
                    {t('history.2015.content')}
                  </h5>
                </CardBody>
              </Card>
            </CustomFade>
            <CustomFade direction="up">
              <Card plain blog className={classes.card4}>
                <CardHeader image plain>
                  <img
                    src={
                      'https://i.ibb.co/gyzs2d9/Screenshot-2020-10-25-114831.png'
                    }
                    alt="..."
                  />
                  <div
                    className={classes.coloredShadow}
                    style={{
                      backgroundImage: `url(https://i.ibb.co/gyzs2d9/Screenshot-2020-10-25-114831.png)`,
                      opacity: '1',
                    }}
                  />
                </CardHeader>
                <CardBody plain>
                  <Info>
                    <h6 className={classes.cardCategory}>{`2016-${
                      1900 + new Date().getYear()
                    }`}</h6>
                  </Info>
                  <h3 className={classes.cardTitle}>{t('history.2016.title')}</h3>
                  <h5 className={classes.description}>
                    {t('history.2016.content')}
                  </h5>
                </CardBody>
              </Card>
            </CustomFade>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  )
}

export default SectionHistory

import React, {useState} from "react";
import { useTrail, animated } from 'react-spring'
import { InView } from 'react-intersection-observer'
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import MailIcon from "@material-ui/icons/Mail";
import PhoneIcon from "@material-ui/icons/Phone";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

import teamsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/teamsStyle.js";
import { teamList } from "data/teams.js";
import { useTranslation } from "react-i18next";
import BackgroundImage from 'assets/img/myImg/common/about_team.jpeg'

const useStyles = makeStyles(teamsStyle);
export default function SectionTeams({ ...rest }) {
  const classes = useStyles();
  const [show,setShow] = useState(false)
  const {t} = useTranslation('AboutUsPage')
  const fadeUp = useTrail(teamList.length, {
    to: {
      opacity: show ? 1 : 0,
      transform: show ? 'translate(0,0)' : 'translate(0,100px)',
    },
    config: { duration: 500 },
    delay: 500,
  })
  return (
    <div className='cd-section' {...rest}>
      <div
        className={classes.team + " " + classes.section}
        style={{
          backgroundImage: `url(${BackgroundImage})`,
        }}
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={8}
              md={8}
              className={
                classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
              }
            >
              <h2 className={classes.title}>{t('team.title')}</h2>
            </GridItem>
          </GridContainer>
          <InView tag="div" onChange={inview => inview && setShow(true)}>
            <GridContainer>
              {fadeUp.map(({...style}, i) => (
                <GridItem xs={12} sm={4} md={4}>
                  <animated.div style={style} key={i}>
                    <Card>
                      <CardHeader color='primary' className={classes.textCenter}>
                        {t(teamList[i].position)}
                      </CardHeader>
                      <CardBody>
                        {/* <h4 className={classes.cardTitle}>{teamList[i].name}</h4>
                        {teamList[i].description && <p className={classes.description}>{teamList[i].description}</p>}
                        <hr /> */}
                        <GridContainer>
                          <GridItem xs={2} sm={2} md={2}>
                            <MailIcon />
                          </GridItem>
                          <GridItem xs={8} sm={9} md={10}>
                            {teamList[i].email}
                          </GridItem>
                        </GridContainer>
                          {teamList[i].phone && 
                        <GridContainer>
                          <GridItem xs={2} sm={2} md={2}>
                            <PhoneIcon />
                          </GridItem>
                          <GridItem xs={8} sm={9} md={10}>
                            {teamList[i].phone}
                          </GridItem>
                        </GridContainer>
                        }
                      </CardBody>
                    </Card>
                  </animated.div>
                </GridItem>
              ))}
            </GridContainer>
          </InView>
        </div>
      </div>
    </div>
  )
}

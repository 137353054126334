/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
// sections for this page
import SectionDescription from "views/AboutUsPage/Sections/SectionDescription.js";
import SectionOffice from "views/AboutUsPage/Sections/SectionOffice.js";
import SectionHistory from "views/AboutUsPage/Sections/SectionHistory.js";
import SectionTeam from "views/AboutUsPage/Sections/SectionTeam.js"

import aboutUsStyle from "assets/jss/material-kit-pro-react/views/aboutUsStyle.js";
import { useTranslation } from "react-i18next";

import BackgroundImage from 'assets/img/myImg/common/home_service.jpeg'

const useStyles = makeStyles(aboutUsStyle);

export default function AboutUsPage() {
  const classes = useStyles();
  const {t} = useTranslation('AboutUsPage')
  return (
    <div>
      <Parallax
        image={BackgroundImage}
        filter='dark'
        small
      >
        <div className={classes.container}>
          <GridContainer justify='center'>
            <GridItem
              md={8}
              sm={8}
              className={classNames(
                classes.mlAuto,
                classes.mrAuto,
                classes.textCenter
              )}
            >
              <h1 className={classes.title}>{t('about.us')}</h1>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <SectionOffice />
        </div>
      </div>
    </div>
  )
}

import React from "react"
import { Email, Item } from "react-html-email";

const EmailHTML = ({ fistname,lastname, email, phone, children }) => {
  return (
    <Email title='Customer Contact'>
      <Item>
        <h2>
          Email from Alphatech Customer{" "}
          <span role='img' aria-label="emoji">
            📧
          </span>
        </h2>
        <hr />
        <p>
          <span style={{ fontWeight: "bold" }}>Name :</span> {fistname}{" "}
          {lastname}
        </p>
        <p>
          <span style={{ fontWeight: "bold" }}>Email address :</span> {email}
        </p>
        <p>
          <span style={{ fontWeight: "bold" }}>Phone no :</span> {phone}
        </p>
        <p>
          <span style={{ fontWeight: "bold" }}>Message:</span> {children}
        </p>
      </Item>
    </Email>
  )
}

export default EmailHTML;

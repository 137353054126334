import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import './styles.css'

const ReadMore = ({ children }) => {
  const text = children
  const textLen = text.length
  const { t } = useTranslation('component')
  const [isReadMore, setIsReadMore] = useState(true)
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore)
  }

  return (
    <p className="text">
      {isReadMore ? text.slice(0, 150) : text}
      {textLen > 150 && (
        <span onClick={toggleReadMore} className="read-or-hide">
          {isReadMore ? `${t('readMore')}` : `${t('showLess')}`}
        </span>
      )}
    </p>
  )
}

export default ReadMore

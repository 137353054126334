import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Header from "components/Header/Header.js";

import AlphatechLogo from "assets/img/myImg/logo.png";
import landingPageStyle from "assets/jss/material-kit-pro-react/views/landingPageStyle.js";

import NavLinks from "./NavLinks.js";

const useStyles = makeStyles(landingPageStyle);

const CustomHeader = ({ ...rest }) => {
  const classes = useStyles();
  return (
    <Header
      color='white'
      brand={
        <img src={AlphatechLogo} alt='logo' className={classes.brandLogo}/>
      }
      links={<NavLinks />}
      fixed
      {...rest}
    />
  );
};

export default CustomHeader;

import Formula from 'assets/img/myImg/formula.svg'
// import Verify from 'assets/img/myImg/verify.svg'
import Train from 'assets/img/myImg/train.svg'
// import Apply from 'assets/img/myImg/apply.svg'

export const serviceList = [
  {
    title: 'services.formulation.title',
    description:
      'services.formulation',
    icon: Formula,
  },
  // {
  //   title: 'services.quality.title',
  //   description: 'services.quality',
  //   icon: Verify,
  // },
  {
    title: 'services.training.title',
    description: 'services.training',
    icon: Train,
  },
  // {
  //   title: 'services.application.title',
  //   description: 'services.application',
  //   icon: Apply,
  // },
]

import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Parallax from "components/Parallax/Parallax.js"
import SectionProducts from "views/ProductsPage/Sections/SectionProducts"
import activeIngredientPageStyle from "assets/jss/material-kit-pro-react/views/productsPageStyle.js";
import BackgroundImage from 'assets/img/myImg/common/products_cover.jpeg'

const useStyles = makeStyles(activeIngredientPageStyle);

const ProductsPage = () => {
  const classes = useStyles();
  return (
    <div>
      <Parallax image={BackgroundImage} filter='dark' small />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <SectionProducts />
      </div>
    </div>
  )
};

export default ProductsPage;

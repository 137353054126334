import {
  container,
  title,
  description,
  mainRaised,
  main,
  whiteColor,
  mlAuto,
} from "assets/jss/material-kit-pro-react.js";

const blogPostPageStyle = (theme) => ({
  mainRaised,
  main,
  title: {
    ...title,
    "&, & + h4": {
      color: whiteColor,
    },
  },
  description: {
    ...description,
    color: whiteColor,
    opacity: 0.8,
  },
  container: {
    ...container,
    zIndex: "2",
  },
  descriptionContainer:{
    ...mlAuto,
    width: "50%",
    [theme.breakpoints.down('sm')]:{
      width:"100%"
    }
  },
  textRight: {
    textAlign: "right",
  },
  block: {
    color: "inherit",
    padding: "0.9375rem",
    fontWeight: "500",
    fontSize: "12px",
    textTransform: "uppercase",
    borderRadius: "3px",
    textDecoration: "none",
    position: "relative",
    display: "block",
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0px",
    width: "auto",
  },
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0",
  },
  left: {
    float: "left!important",
    display: "block",
  },
  right: {
    padding: "15px 0",
    margin: "0",
    float: "right",
  },
  icon: {
    width: "18px",
    height: "18px",
    top: "3px",
    position: "relative",
  },
})

export default blogPostPageStyle;

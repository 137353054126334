import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'

import Footer from 'components/Footer/Footer.js'

import Facebook from 'assets/img/myImg/facebook.svg'
import LineApp from 'assets/img/myImg/line.svg'
import styles from 'assets/jss/material-kit-pro-react/views/componentsSections/footerStyle.js'

const useStyles = makeStyles(styles)

const CustomFooter = () => {
  const classes = useStyles()
  return (
    <Footer
      content={
        <Grid container alignItems="center">
          <Grid item xs={12} sm={6}>
            <Box display="flex">
              <Box>
                <a
                  href="https://www.facebook.com/ALPHATECHSCIENCE"
                  className={classes.block}
                >
                  <img src={Facebook} alt="facebook" className={classes.icon} />
                </a>
              </Box>
              <Box>
                <a
                  href="https://liff.line.me/1645278921-kWRPP32q/?accountId=alphatechscience"
                  className={classes.block}
                >
                  <img src={LineApp} alt="Line" className={classes.icon} />
                </a>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            Copyright &copy; {1900 + new Date().getYear()} ,{' '}
            <a href="http://www.alphatechscience.com">
              AlphaTech Science (Thailand) Co., Ltd.
            </a>{' '}
          </Grid>
        </Grid>
      }
    />
  )
}
export default CustomFooter

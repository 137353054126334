import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useTrail, animated } from 'react-spring'
import { InView } from 'react-intersection-observer'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
// @material-ui/icons
import { Element } from 'react-scroll'

// core components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import InfoArea from 'components/InfoArea/InfoArea.js'
import CustomFade from 'components/CustomFade'
import productStyle from 'assets/jss/material-kit-pro-react/views/landingPageSections/productStyle.js'
import { signatures } from 'data/signature'

const useStyles = makeStyles(productStyle)

export default function SectionProduct() {
  const [show, setShow] = useState(false)
  const classes = useStyles()
  const { t } = useTranslation('LandingPage')
  const trail = useTrail(signatures.length, {
    to: {
      opacity: show ? 1 : 0,
      transform: show ? 'translate(0,0)' : 'translate(0,100px)',
    },
    config: { duration: 500 },
    delay: 500,
  })

  const onInViewChange = (inview) => {
    if (!show && inview) {
      setShow(true)
    }
  }
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={8} md={8}>
          <CustomFade direction="up">
            <Element name="description">
              <h2 className={classes.title}>{t('intro.title')}</h2>
            </Element>
            <h5 className={classes.description}>{t('intro.full')}</h5>
            <br />
            <h5 className={classes.description}>{t('intro.add')}</h5>
          </CustomFade>
        </GridItem>
      </GridContainer>
      <InView tag="div" onChange={onInViewChange}>
        <GridContainer>
          {trail.map(({ ...style }, index) => (
            <GridItem xs={12} sm={4} md={4} key={index}>
              <animated.div key={index} style={style}>
                <InfoArea
                  title={t(signatures[index].title)}
                  description={t(signatures[index].description)}
                  icon={signatures[index].icon}
                  vertical
                />
              </animated.div>
            </GridItem>
          ))}
        </GridContainer>
      </InView>
    </div>
  )
}

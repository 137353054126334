import Acne from 'assets/img/myImg/products/acne.png'
import Age from 'assets/img/myImg/products/age.png'
import Inflamatory from 'assets/img/myImg/products/inflamatory.png'
import Oxidant from 'assets/img/myImg/products/oxidant.png'
import Bead from 'assets/img/myImg/products/bead.png'
import Color from 'assets/img/myImg/products/color.png'
import Cool from 'assets/img/myImg/products/cool.png'
import Emulsifier from 'assets/img/myImg/products/emulsifier.png'
import Animal from 'assets/img/myImg/products/animal.png'
import Plant from 'assets/img/myImg/products/plant.png'
import Cell from 'assets/img/myImg/products/cell.png'
import Film from 'assets/img/myImg/products/film.png'
import Hair from 'assets/img/myImg/products/hair.png'
import HairConditioner from 'assets/img/myImg/products/hair-conditioner.png'
import HairGrow from 'assets/img/myImg/products/hair-grow.png'
import Moisturizer from 'assets/img/myImg/products/moisturizer.png'
import Peptide from 'assets/img/myImg/products/peptide.png'
import Pick from 'assets/img/myImg/products/pick.png'
import Preservative from 'assets/img/myImg/products/preservative.png'
import Probiotic from 'assets/img/myImg/products/probiotic.png'
import Slip from 'assets/img/myImg/products/slip.png'
import SunScreen from 'assets/img/myImg/products/sun-screen.png'
import Thick from 'assets/img/myImg/products/thick.png'
import White from 'assets/img/myImg/products/white.png'

export const products = [
  {
    id: 1,
    title: 'Anti-Acne',
    icon: Acne,
    items: [
      {
        name: 'MultiSal® Salicylic Acid 30',
      },
      {
        name: 'SalSphere® Salicylic Acid 30',
      },
      {
        name: 'SalSphere® Severe Acne Relief',
      },
      {
        name: 'SalSphere® Even Skin',
      },
    ],
  },
  {
    id: 2,
    title: 'Anti-aging',
    icon: Age,
    items: [
      {
        name: 'MultiSal® Retinol',
      },
      {
        name: 'SalSphere® Anti-Aging Lift',
      },
      {
        name: 'SalSphere® Natural Anti-Aging',
      },
    ],
  },
  {
    id: 3,
    title: 'Anti-inflamatory',
    icon: Inflamatory,
    items: [
      {
        name: 'MultiSal® Sensitive Skin',
      },
    ],
  },
  {
    id: 4,
    title: 'Anti-oxidant',
    icon: Oxidant,
    items: [
      {
        name: 'MultiSal® Vitamin C+E',
      },
      {
        name: 'MultiSal® Astraxanthin',
      },
    ],
  },
  {
    id: 5,
    title: 'Bead',
    icon: Bead,
    items: [
      {
        name: 'SalColorsFx™ (White to Red)',
      },
      {
        name: 'SalColorsFx™ (White to Green)',
      },
    ],
  },
  {
    id: 6,
    title: 'Colorant',
    icon: Color,
    items: [
      {
        name: 'Elara UltraViolet™ Pigment',
      },
    ],
  },
  {
    id: 7,
    title: 'Cooling Agent',
    icon: Cool,
    items: [
      {
        name: 'HydroSal2® Salcool',
      },
      {
        name: 'HydroSal® Freshcool',
      },
      {
        name: 'MultiSal® Menthol',
      },
    ],
  },
  {
    id: 8,
    title: 'Emulsifier',
    icon: Emulsifier,
    items: [
      {
        name: 'S-Face 10G-IS',
      },
      {
        name: 'S-Face 10G-L',
      },
      {
        name: 'S-Face VL 211',
      },
      {
        name: 'S-Face VL 212',
      },
    ],
  },
  {
    id: 9,
    title: 'Extract-Animal',
    icon: Animal,
    items: [
      {
        name: 'IndiEx Propolis Extract',
      },
      {
        name: 'IndiEx Royal jelly Extract',
      },
      {
        name: 'IndiEx Salmon DNA',
      },
    ],
  },
  {
    id: 10,
    title: 'Extract-Plant',
    icon: Plant,
    items: [
      {
        name: 'INNOVALOE ALOE VERA GEL SPRAY DRIED POWDER 200:1',
      },
      {
        name: 'EX085PB1W1 Botaniz Snow Lotus Extract',
      },
      {
        name: 'IndiEx Red Love Extract (N)',
      },
      {
        name: 'IndiEx Sakura Extract',
      },
      {
        name: 'White Strawberry Extract',
      },
    ],
  },
  {
    id: 11,
    title: 'Extract-Stemcell',
    icon: Cell,
    items: [
      {
        name: 'Edelweiss Callus Culture Extract',
      },
    ],
  },
  {
    id: 12,
    title: 'Film Former',
    icon: Film,
    items: [
      {
        name: 'Perisol 300A',
      },
    ],
  },
  {
    id: 13,
    title: 'Hair Colorant',
    icon: Hair,
    items: [
      {
        name: 'Xiny Color ASH',
      },
      {
        name: 'Acid Violet 43',
      },
    ],
  },
  {
    id: 14,
    title: 'Hair Conditioner',
    icon: HairConditioner,
    items: [
      {
        name: 'Polycos CA-3001',
      },
      {
        name: 'Polycos CA-3000',
      },
    ],
  },
  {
    id: 15,
    title: 'Hair Growth',
    icon: HairGrow,
    items: [
      {
        name: 'SalSphere® Apple Extract',
      },
      {
        name: 'SalSphere® Hair Stimulator',
      },
    ],
  },
  {
    id: 16,
    title: 'Moisturizer',
    icon: Moisturizer,
    items: [
      {
        name: 'SalSphere® Aqua Skin',
      },
      {
        name: 'Diglycerin S',
      },
      {
        name: 'IndiHA SPM(N)',
      },
    ],
  },
  {
    id: 17,
    title: 'Peptide',
    icon: Peptide,
    items: [
      {
        name: 'MHY498',
      },
      {
        name: 'NP7',
      },
      {
        name: 'Leu-Enkephalin',
      },
    ],
  },
  {
    id: 18,
    title: 'Pigment',
    icon: Pick,
    items: [
      {
        name: 'Ti-K TiO2 407 AS',
      },
      {
        name: 'Ti-K TiO2 CR50 AS',
      },
      {
        name: 'Ti-K Black Iron Oxide AS',
      },
      {
        name: 'Ti-K Red Iron Oxide AS',
      },
      {
        name: 'Ti-K Yellow Iron Oxide AS',
      },
    ],
  },
  {
    id: 19,
    title: 'Preservative',
    icon: Preservative,
    items: [
      {
        name: 'BH-800 (1,2-Hexanediol)',
      },
      {
        name: 'BNB-G19',
      },
      {
        name: 'HydroSal® BKC',
      },
    ],
  },
  {
    id: 20,
    title: 'Probiotic',
    icon: Probiotic,
    items: [
      {
        name: 'IndiFer.B Bifida Lysate',
      },
      {
        name: 'IndiFer.G Galactomyces (N)',
      },
    ],
  },
  {
    id: 21,
    title: 'Slip Modifier',
    icon: Slip,
    items: [
      {
        name: 'Anybes (Nylon12)',
      },
    ],
  },
  {
    id: 22,
    title: 'Sunscreen',
    icon: SunScreen,
    items: [
      {
        name: 'Ti-K Zinc K AS',
      },
      {
        name: 'Ti-K TiO2 10N STA',
      },
      {
        name: 'Ti-K Zinc N AS',
      },
      {
        name: 'Zano 10 Plus',
      },
      {
        name: 'Ethylhexyl Triazone (UVT-150)',
      },
    ],
  },
  {
    id: 23,
    title: 'Thickener',
    icon: Thick,
    items: [
      {
        name: 'Carbomer 21',
      },
      {
        name: 'Carbomer 940',
      },
    ],
  },
  {
    id: 24,
    title: 'Whitening',
    icon: White,
    items: [
      {
        name: 'COS-VCE',
      },
      {
        name: 'Tranexamic Acid',
      },
      {
        name: 'MultiSal® Multilayer',
      },
      {
        name: 'SalSphere® Light',
      },
    ],
  },
]

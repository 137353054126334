import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import NativeSelect from "@material-ui/core/NativeSelect";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

import Button from "components/CustomButtons/Button.js";
// import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import headersStyle from "assets/jss/material-kit-pro-react/components/headerLinksStyle.js";
import selectStyle from "assets/jss/material-kit-pro-react/customSelectStyle.js";
import BootstrapInput from "./BootstrapInput.js"

const useStyles = makeStyles(headersStyle);
const useStylesSelect = makeStyles(selectStyle);

const NavLinks = () => {
  let history = useHistory();
  const classes = useStyles();
  const classesSelect = useStylesSelect();
  const {t,i18n} = useTranslation('navbar')
  const [lang, setLang] = useState(i18n.language);

  const changeLanguage = (event) => {
    setLang(event.target.value);
    i18n.changeLanguage(event.target.value)
  };

  const handleNavigate = path => {
    history.push(path);
  }

  // const handleDropdownNav = txt => {
  //   const path = txt.toLowerCase().replace(" ","-")
  //   history.push(`/${path}`)
  // }

  return (
    <div className={classes.collapse}>
      <List className={classes.list + " " + classes.mrAuto}>
        <ListItem className={classes.listItem}>
          <Button
            className={classes.navLink}
            onClick={() => handleNavigate("/")}
            color='transparent'
          >
            {t('home')}
          </Button>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Button
            className={classes.navLink}
            onClick={() => handleNavigate("/about")}
            color='transparent'
          >
            {t('aboutUs')}
          </Button>
        </ListItem>
        <ListItem className={classes.listItem}>
        <Button
            className={classes.navLink}
            onClick={() => handleNavigate("/products")}
            color='transparent'
          >
            {t('product')}
          </Button>
          {/* <CustomDropdown
            buttonText={`${t('product')}`}
            buttonProps={{
              color: "transparent",
            }}
            dropdownList={[
              "Active Ingredient",
              "Colour Cosmetics",
              "Suncare",
              "Emulsifier",
              "Natural",
              "Food Additive",
            ]}
            onClick={handleDropdownNav}
          >
            {t('product')}
          </CustomDropdown> */}
        </ListItem>
        <ListItem className={classes.listItem}>
          <Button
            className={classes.navLink}
            onClick={() => handleNavigate("/contact")}
            color='transparent'
          >
            {t('contactUs')}
          </Button>
        </ListItem>
      </List>
      <List className={classes.list + " " + classes.mlAuto}>
        <ListItem className={classes.listItem}>
          <FormControl
            variant='outlined'
            className={classesSelect.selectFormControl}
          >
            <InputLabel htmlFor='demo-customized-select-native'></InputLabel>
            <NativeSelect
              value={lang}
              onChange={changeLanguage}
              id='demo-customized-select-native'
              input={<BootstrapInput />}
            >
              <option value='en'>English</option>
              <option value='th'>ไทย</option>
            </NativeSelect>
          </FormControl>
        </ListItem>
      </List>
    </div>
  );
};

export default NavLinks;

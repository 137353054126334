import React from 'react'
import { Parallax } from 'react-parallax'
import { useTranslation } from 'react-i18next'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
// core components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import InfoArea from 'components/InfoArea/InfoArea.js'
import CustomFade from 'components/CustomFade'

import featuresStyle from 'assets/jss/material-kit-pro-react/views/sectionsSections/featuresStyle.js'
import { serviceList } from 'data/services.js'
import BackgroundImage from 'assets/img/myImg/common/home_service.jpeg'

const useStyles = makeStyles(featuresStyle)
const SectionFeature = () => {
  const {t} = useTranslation('LandingPage')
  const classes = useStyles()
  return (
    <Parallax bgImage={BackgroundImage} bgImageAlt="feature" strength={200}>
      <div className={classes.features5}>
        <GridContainer>
          <GridItem
            xs={12}
            sm={8}
            md={8}
            className={
              classes.mlAuto + ' ' + classes.mrAuto + ' ' + classes.textCenter
            }
          >
            <h2 className={classes.title}>{t('services.title')}</h2>
          </GridItem>
          <div className={classes.container}>
            <GridContainer className={classes.gridContainer}>
              {serviceList.map((item, i) => (
                <GridItem xs={12} sm={6} key={`${item.title}-${i}`}>
                  <CustomFade>
                    <InfoArea
                      vertical
                      className={classes.infoArea5}
                      icon={item.icon}
                      iconColor="primary"
                      title={t(item.title)}
                      description={t(item.description)}
                    />
                  </CustomFade>
                </GridItem>
              ))}
            </GridContainer>
          </div>
        </GridContainer>
      </div>
    </Parallax>
  )
}

export default SectionFeature

import React from 'react'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
// import { Link } from 'react-scroll'
import { useTranslation } from 'react-i18next'

// core components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
// import Button from 'components/CustomButtons/Button.js'
import Parallax from 'components/Parallax/Parallax.js'
import CustomFade from 'components/CustomFade'
import landingPageStyle from 'assets/jss/material-kit-pro-react/views/landingPageStyle.js'

// Sections for this page
import SectionProduct from './Sections/SectionProduct.js'
import SectionClient from './Sections/SectionClients.js'
// import SectionEvent from './Sections/SectionsEvent.js'
import SectionFeatures from './Sections/SectionFeature'
// import SectionSolution from './Sections/SectionSolution'
// import SectionPartner from './Sections/SectionPartner.js'

import BackgroundImage from 'assets/img/myImg/common/home_cover.jpg'

const useStyles = makeStyles(landingPageStyle)

export default function LandingPage() {
  const classes = useStyles()
  const { t } = useTranslation(['LandingPage','component'])
  return (
    <div>
      <Parallax image={BackgroundImage} filter="dark">
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={6} md={6}>
              <CustomFade direction="left">
                <h1 className={classes.title}>
                  {t('alphatech')}
                </h1>
                <h4>{t('intro')}</h4>
              </CustomFade>
              {/* <br /> */}
              {/* <CustomFade direction="up">
                <Link to="description" smooth duration={1000} offset={-100}>
                  <Button color="primary" size="lg" href="#" className={classes.button}>
                    {t('component:read.more')}
                  </Button>
                </Link>
              </CustomFade> */}
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <SectionProduct />
          <SectionClient />
        </div>
        {/* <SectionPartner /> */}
        <SectionFeatures />
        {/* <SectionSolution /> */}
        {/* <SectionEvent /> */}
      </div>
    </div>
  )
}

import React, { useState } from 'react'
import { useSpring, animated } from 'react-spring'
import { InView } from 'react-intersection-observer'

const CustomFade = ({ children, direction="center" }) => {
  const [show, setShow] = useState(false)
  const fadeInLeft = useSpring({
    to: {
      opacity: show ? 1 : 0,
      transform: show ? 'translate(0,0)' : 'translate(-100px,0)',
    },
    config: { duration: 500 },
    delay: 500,
  })
  const fadeInRight = useSpring({
    to: {
      opacity: show ? 1 : 0,
      transform: show ? 'translate(0,0)' : 'translate(100px,0)',
    },
    config: { duration: 500 },
    delay: 500,
  })
  const fadeUp = useSpring({
    to: {
      opacity: show ? 1 : 0,
      transform: show ? 'translate(0,0)' : 'translate(0,100px)',
    },
    config: { duration: 500 },
    delay: 500,
  })
  const fadeDown = useSpring({
    to: {
      opacity: show ? 1 : 0,
      transform: show ? 'translate(0,0)' : 'translate(0,-100px)',
    },
    config: { duration: 500 },
    delay: 500,
  })
  const fade = useSpring({
    to: {
      opacity: show ? 1 : 0,
    },
    config: { duration: 500 },
    delay: 500,
  })
  

  const onInViewChange = (inview) => {
    if (!show && inview) {
      setShow(true)
    }
  }
  const getDirection = () => {
    switch (direction){
      case "up":
        return fadeUp
      case "left":
        return fadeInLeft
      case "right":
        return fadeInRight
      case "down":
        return fadeDown
      default:
        return fade
    }
  }
  return (
    <InView tag="div" onChange={onInViewChange}>
      <animated.div style={getDirection()}>{children}</animated.div>
    </InView>
  )
}

export default CustomFade

import {
  container,
  title,
  cardTitle,
  description,
  mlAuto,
  mrAuto,
  section,
  sectionDark,
  coloredShadow,
  blackColor,
  whiteColor,
  hexToRgb,
} from 'assets/jss/material-kit-pro-react.js'

const teamsSection = (theme) => ({
  container,
  title,
  mlAuto,
  mrAuto,
  cardTitle,
  coloredShadow,
  description,
  descriptionWhite: {
    ...description,
  },
  textCenter: {
    textAlign: 'center',
  },
  team: {
    backgroundColor: whiteColor,
    // padding: '80px 0',
    // '& h5$description,& h5$descriptionWhite': {
    //   marginBottom: '80px',
    // },
  },
  section: {
    ...sectionDark,
    ...section,
    position: 'relative',
    '& $title': {
      color: whiteColor,
    },
    '& $descriptionWhite': {
      color: 'rgba(' + hexToRgb(whiteColor) + ', 0.76)',
    },
    '& $container': {
      zIndex: '2',
      position: 'relative',
    },
    '&:after': {
      position: 'absolute',
      zIndex: '1',
      width: '100%',
      height: '100%',
      display: 'block',
      left: '0',
      top: '0',
      content: "''",
      backgroundColor: 'rgba(' + hexToRgb(blackColor) + ', 0.7)',
    },
  },
  justifyContent: {
    WebkitBoxPack: 'center !important',
    MsFlexPack: 'center !important',
    justifyContent: 'center !important',
  },
  cardCategory: {
    marginTop: '10px',
  },
  btn: {
    marginTop: '0 !important',
  },
  card3: {
    textAlign: 'left',
  },
  card5: {
    textAlign: 'left',
    '& $cardTitle': {
      color: whiteColor,
    },
    '& $description': {
      color: whiteColor,
    },
  },
  imgLogo: {
    width: '100%',
    transition: 'all .2s',
    '&:hover': {
      transform: 'scale(1.1)',
    },
  },
  imgContainer: {
    width: '240px !important',
    height: 'auto !important',
    [theme.breakpoints.down('md')]: {
      width: '220px !important',
    },
    [theme.breakpoints.between('xs','sm')]: {
      width: '180px !important',
    },
  },
  sliderContainer: {
    paddingBottom: '80px',
    margin: '0 auto',
  },
  carouselStyle: {
    textAlign: 'center',
    padding: '0 80px',
    [theme.breakpoints.down('md')]: {
      padding: '0',
    },
  },
})

export default teamsSection

import Solution from 'assets/img/myImg/solution.svg'
import Service from 'assets/img/myImg/service.svg'
import Unique from 'assets/img/myImg/unique.svg'

export const signatures = [
  {
    title: 'solutionProvider.title',
    description: 'solutionProvider',
    icon: Solution,
  },
  {
    title: 'one.stop.service.title',
    description: 'oneStopService',
    icon: Service,
  },
  {
    title: 'uniqueness.title',
    description: 'uniqueness',
    icon: Unique,
  },
]

import {
    successColor
  } from "assets/jss/material-kit-pro-react.js";

const productCollapseCardStyle = (theme) => ({
    container: {
        borderRadius: '16px',
        border: `2px solid ${successColor[1]}`,
        padding: '18px',
        transition: 'all .2s',
        cursor: 'pointer',
        '&:hover': {
            background: successColor[4]
        }
    },
    title: {
        fontWeight: 500
    },
    icon: {
        width: 32,
        height: 32   
    },
    expandIcon: {
        transform: 'rotate(0deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
          }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    }
})

export default productCollapseCardStyle
import React, { useEffect, Fragment, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useLocation } from "react-router-dom";

import CustomHeader from "components/CustomHeader";
import CustomFooter from "components/CustomFooter";
import LandingPage from "views/LandingPage/LandingPage.js";
import AboutUsPage from "views/AboutUsPage/AboutUsPage.js";
import ContactUsPage from "views/ContactUsPage/ContactUsPage.js";
import ProductsPage from "views/ProductsPage"
// import CosmeticsPage from "views/CosmeticsPage";
// import SuncarePage from "views/SuncarePage";
// import EmulsifierPage from "views/EmulsifierPage";
// import ExtractPage from "views/ExtractPage";
// import FoodPage from "views/FoodPage";

const App = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Fragment>
      <Suspense fallback={null}>
        <CustomHeader />
        <Switch>
          <Route exact path='/' component={LandingPage} />
          {/* <Route path='/active-ingredient' component={ActiveIngredientPage} />
          <Route path='/colour-cosmetics' component={CosmeticsPage} />
          <Route path='/suncare' component={SuncarePage} />
          <Route path='/emulsifier' component={EmulsifierPage} />
          <Route path='/natural' component={ExtractPage} />
          <Route path='/food-additive' component={FoodPage} /> */}
          <Route path='/products' component={ProductsPage} />
          <Route path='/about' component={AboutUsPage} />
          <Route path='/contact' component={ContactUsPage} />
          <Redirect to='/' />
        </Switch>
        <CustomFooter />
      </Suspense>
    </Fragment>
  );
};

export default App;

import React, { useState } from 'react'
import clsx from 'clsx'
import {
  Box,
  Collapse,
  makeStyles,
  List,
  ListItem,
  ListItemIcon,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import LabelIcon from '@material-ui/icons/Label'
import productCollapseCardStyle from 'assets/jss/material-kit-pro-react/components/productCollapseCardStyle'

const useStyles = makeStyles(productCollapseCardStyle)

const ProductCollapseCard = ({ title, icon, items }) => {
  const classes = useStyles()
  const [expanded, setExpanded] = useState(false)
  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  return (
    <Box className={classes.container} onClick={handleExpandClick}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex" gridGap={24} alignItems="center">
          <img src={icon} alt={title} className={classes.icon} />
          <h4 className={classes.title}>{title}</h4>
        </Box>
        <ExpandMoreIcon
          className={clsx(classes.expandIcon, {
            [classes.expandOpen]: expanded,
          })}
        />
      </Box>
      <Collapse in={expanded}>
        <List>
          {items.map((item) => (
            <ListItem key={item.name}>
              <ListItemIcon>
                <LabelIcon htmlColor="#66bb6a" />
              </ListItemIcon>
              {item.name}
            </ListItem>
          ))}
        </List>
      </Collapse>
    </Box>
  )
}

export default ProductCollapseCard

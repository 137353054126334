/* eslint-disable */
import React, { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSpring, animated } from 'react-spring'
import { InView } from 'react-intersection-observer'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
// @material-ui/icons
// import EcoIcon from '@material-ui/icons/Eco'
// import SecurityIcon from '@material-ui/icons/Security'

// core components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
// import InfoArea from 'components/InfoArea/InfoArea.js'
import projectsStyle from 'assets/jss/material-kit-pro-react/views/sectionsSections/projectsStyle.js'

import SalvonaLogo from 'assets/img/myImg/salvona-logo.svg'
import AthenaLogo from 'assets/img/myImg/athena-logo.png'
// import Feather from 'assets/img/myImg/feather.svg'
// import LongLasting from 'assets/img/myImg/24-hours.svg'

const useStyles = makeStyles(projectsStyle)

export default function SectionProjects({ ...rest }) {
  const clientClasses = useStyles()
  const [show1, setShow1] = useState(false)
  const [show2, setShow2] = useState(false)
  const isPhone = useMediaQuery('(max-width:600px)')
  const { t } = useTranslation('LandingPage')
  const fadeInLeft = useSpring({
    to: {
      opacity: show1 ? 1 : 0,
      transform: show1 ? 'translate(0,0)' : 'translate(-100px,0)',
    },
    config: { duration: 500 },
    delay: 500,
  })
  const fadeInRight = useSpring({
    to: {
      opacity: show2 ? 1 : 0,
      transform: show2 ? 'translate(0,0)' : 'translate(100px,0)',
    },
    config: { duration: 500 },
    delay: 500,
  })

  return (
    <Fragment>
      <div className="cd-section" {...rest}>
        <div className={clientClasses.projects + ' ' + clientClasses.projects4}>
          <div className={clientClasses.container}>
            <GridContainer>
              <GridItem
                xs={12}
                sm={8}
                md={8}
                className={
                  clientClasses.mlAuto +
                  ' ' +
                  clientClasses.mrAuto +
                  ' ' +
                  clientClasses.textCenter
                }
              >
                <h2 className={clientClasses.title}>{t('partner')}</h2>
                <div className={clientClasses.sectionSpace} />
              </GridItem>
            </GridContainer>
            <InView tag="div" onChange={(inview) => inview && setShow1(true)}>
              <animated.div style={fadeInLeft}>
                <GridContainer>
                  <GridItem
                    xs={12}
                    sm={5}
                    md={5}
                    className={clientClasses.mlAuto}
                  >
                    <a href="https://www.salvona.com/">
                      <img
                        src={SalvonaLogo}
                        alt="Salvona"
                        className={clientClasses.image}
                      />
                    </a>
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={5}
                    md={5}
                    className={clientClasses.mrAuto}
                  >
                    <h3 className={clientClasses.clientTitle}>Salvona</h3>
                    <div className={clientClasses.clientDescription}>
                      {t('salvona')}
                    </div>
                    {/* <InfoArea
                      className={clientClasses.info4}
                      title={t('salvona.natural.title')}
                      description={t('salvona.natural')}
                      icon={EcoIcon}
                      iconColor="success"
                    />
                    <InfoArea
                      className={clientClasses.info4}
                      title={t('salvona.skin.title')}
                      description={t('salvona.skin')}
                      icon={SecurityIcon}
                      iconColor="primary"
                    /> */}
                  </GridItem>
                </GridContainer>
              </animated.div>
            </InView>
            <br />
            <InView tag="div" onChange={(inview) => inview && setShow2(true)}>
              <animated.div style={fadeInRight}>
                <GridContainer>
                  {isPhone ? (
                    <GridItem
                      xs={12}
                      sm={5}
                      md={5}
                      className={clientClasses.mrAuto}
                    >
                      <a
                        href="https://www.athena.co.th/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={AthenaLogo}
                          alt="Athena"
                          className={clientClasses.image}
                        />
                      </a>
                    </GridItem>
                  ) : null}
                  <GridItem
                    xs={12}
                    sm={5}
                    md={5}
                    className={clientClasses.mlAuto}
                  >
                    <h3 className={clientClasses.clientTitle}>Athena Corp</h3>
                    <div className={clientClasses.clientDescription}>
                      {t('athena')}
                    </div>
                    {/* <InfoArea
                      className={clientClasses.info4}
                      title={t('athena.soft.title')}
                      description={`${t('athena.soft')}`}
                      icon={Feather}
                      iconColor="success"
                    />
                    <InfoArea
                      className={clientClasses.info4}
                      title={t('athena.long.title')}
                      description={`${t('athena.long')}`}
                      icon={LongLasting}
                      iconColor="info"
                    /> */}
                  </GridItem>
                  {!isPhone && (
                    <GridItem
                      xs={12}
                      sm={5}
                      md={5}
                      className={clientClasses.mrAuto}
                    >
                      <a
                        href="https://www.athena.co.th/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={AthenaLogo}
                          alt="Athena"
                          className={clientClasses.image}
                        />
                      </a>
                    </GridItem>
                  )}
                </GridContainer>
              </animated.div>
            </InView>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

import React, { useState } from 'react'
import { renderEmail } from 'react-html-email'
import axios from 'axios'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import QrCode from 'assets/img/myImg/qrcode.png'
// core components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import InfoArea from 'components/InfoArea/InfoArea.js'
import Card from 'components/Card/Card.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardBody from 'components/Card/CardBody.js'
import CardFooter from 'components/Card/CardFooter.js'
import Button from 'components/CustomButtons/Button.js'
import EmailHTML from 'components/Email'
import contactsStyle from 'assets/jss/material-kit-pro-react/views/sectionsSections/contactsStyle.js'

import Pin from 'assets/img/myImg/pin.svg'
import Phone from 'assets/img/myImg/phone.svg'
import Mail from 'assets/img/myImg/mail.svg'
import Facebook from 'assets/img/myImg/facebook.svg'
import { ReactComponent as Line } from 'assets/img/myImg/line.svg'
import { useTranslation } from 'react-i18next'

import BackgroundImage from 'assets/img/myImg/common/contact.jpeg'

const useStyles = makeStyles(contactsStyle)

export default function ContactUsPage() {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [mailMe, setMailMe] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: '',
  })
  const { t } = useTranslation('contactUs')
  const { firstName, lastName, email, phone, message } = mailMe
  const handleChange = (event) => {
    const { value, name } = event.target
    setMailMe({ ...mailMe, [name]: value })
  }
  const handleSubmit = async (event) => {
    event.preventDefault()
    setLoading(true)
    const messageHtml = renderEmail(
      <EmailHTML
        fistname={firstName}
        lastname={lastName}
        email={email}
        phone={phone}
      >
        {' '}
        {message}
      </EmailHTML>
    )
    await axios({
      method: 'POST',
      url: 'https://alphatech-api.herokuapp.com/api/sendemail',
      data: {
        name: firstName,
        messageHtml: messageHtml,
      },
    }).then((response) => {
      if (response.data.msg === 'success') {
        setLoading(false)
        alert('Send email success')
        document.location.reload(true)
      } else if (response.data.msg === 'fail') {
        setLoading(false)
        console.error(response.data.err);
        alert('Error, please try again or contact via another channel.')
      }
    }).catch(err => {
      console.error(err);
    })
  }

  const emails = (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <a href={'mailto:info@alphatechscience.com'} style={{ color: '#366ed8' }}>
        info@alphatechscience.com
      </a>
      <a href={'mailto:cs@alphatechscience.com'} style={{ color: '#366ed8' }}>
        cs@alphatechscience.com
      </a>
      <a
        href={'mailto:marketing4@alphatechscience.com'}
        style={{ color: '#366ed8' }}
      >
        marketing4@alphatechscience.com
      </a>
    </div>
  )

  return (
    <div
      className={classes.contacts + ' ' + classes.section}
      style={{
        backgroundImage: `url(${BackgroundImage})`,
      }}
    >
      <div className={classes.container}>
        <GridContainer>
          <GridItem xs={12}>
            <h2 className={classes.title}>{t('contact.title')}</h2>
            <h5 className={classes.description}>{t('contact.description')}</h5>
          </GridItem>
          <GridItem xs={12} sm={5} md={5}>
            <InfoArea
              className={classes.infoArea}
              title={t('call')}
              description={
                <a href="tel:0218562756" style={{ color: '#366ed8' }}>
                  +66 2-185-6275-6
                </a>
              }
              icon={Phone}
            />
            <InfoArea
              className={classes.infoArea}
              title={t('mail')}
              description={emails}
              icon={Mail}
            />
            <InfoArea
              className={classes.infoArea}
              title="Facebook"
              description={
                <a
                  href="https://www.facebook.com/ALPHATECHSCIENCE"
                  style={{ color: '#366ed8' }}
                >
                  AlphaTech Science
                </a>
              }
              icon={Facebook}
            />
          </GridItem>
          <GridItem xs={12} sm={5} md={5} className={classes.qrContainer}>
            <h5 className={classes.description}>
              <Line width="32px" height="32px" /> Line QR Code {" "}
              <a
                href="https://liff.line.me/1645278921-kWRPP32q/?accountId=alphatechscience"
                style={{ color: '#366ed8' }}
              >
                @Alphatechscience
              </a>
            </h5>
            <img
              src={QrCode}
              alt="line QRcode"
              className={classes.qrcodeStyle}
            />
          </GridItem>
          <GridItem xs={12}>
            <InfoArea
              className={classes.infoArea}
              title={t('location.title')}
              description={
                <span style={{ color: '#fff' }}>{t('location')}</span>
              }
              icon={Pin}
            />
            <div className={classes.mapContainer}>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3876.49444103994!2d100.67268139811446!3d13.688476989036854!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x311d5f2f89e16e93%3A0x6140959b30cb31af!2z4Lia4Lij4Li04Lip4Lix4LiXIOC4reC4seC4peC4n-C5iOC4siDguYDguJfguIQg4LiL4Liy4Lii4LiZ4LmMKOC4m-C4o-C4sOC5gOC4l-C4qOC5hOC4l-C4oikg4LiI4Liz4LiB4Lix4LiU!5e0!3m2!1sen!2sth!4v1638183455706!5m2!1sen!2sth"
                width="100%"
                height="300"
                allowFullScreen=""
                loading="lazy"
                title="location"
              />
            </div>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <Card className={classes.card1}>
              <form onSubmit={handleSubmit}>
                <CardHeader
                  contact
                  color="primary"
                  className={classes.textCenter}
                >
                  <h4 className={classes.cardTitle}>{t('form.title')}</h4>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={6} md={6}>
                      <TextField
                        type="text"
                        name="firstName"
                        value={firstName}
                        label={t('form.fname')}
                        onChange={handleChange}
                        fullWidth
                        required
                      />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6}>
                      <TextField
                        type="text"
                        name="lastName"
                        value={lastName}
                        label={t('form.lname')}
                        onChange={handleChange}
                        fullWidth
                        required
                      />
                    </GridItem>
                  </GridContainer>
                  <br />
                  <GridContainer>
                    <GridItem xs={12} sm={6} md={6}>
                      <TextField
                        type="email"
                        name="email"
                        value={email}
                        label={t('form.email')}
                        onChange={handleChange}
                        fullWidth
                        required
                      />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6}>
                      <TextField
                        type="text"
                        name="phone"
                        value={phone}
                        label={t('form.phone')}
                        onChange={handleChange}
                        fullWidth
                        required
                      />
                    </GridItem>
                  </GridContainer>
                  <br />
                  <FormControl fullWidth>
                    <TextField
                      type="text"
                      variant="outlined"
                      name="message"
                      value={message}
                      label={t('form.message')}
                      onChange={handleChange}
                      multiline={true}
                      rows={5}
                      required
                    />
                  </FormControl>
                </CardBody>
                <CardFooter className={classes.justifyContentBetween}>
                  <Button
                    color="primary"
                    className={classes.pullRight}
                    type="submit"
                  >
                    {loading ? 'Sending...' : 'Send Message'}
                  </Button>
                </CardFooter>
              </form>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  )
}

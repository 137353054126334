import {
  title,
  description,
  mrAuto,
  mlAuto
} from "assets/jss/material-kit-pro-react.js";
import imagesStyles from "assets/jss/material-kit-pro-react/imagesStyles.js";

const imgRaised = imagesStyles.imgRaised;
const rounded = imagesStyles.imgRounded;
const imgFluid = imagesStyles.imgFluid;

const servicesStyle = (theme) => ({
  title,
  description,
  mrAuto,
  mlAuto,
  textCenter: {
    textAlign: "center!important"
  },
  officeTitle: {
    fontSize: '64px',
    fontWeight: 700,
    [theme.breakpoints.down('sm')]: {
      fontSize: '32px'
    }
  },
  office: {
      margin: "40px 0px",
      [theme.breakpoints.down('sm')]: {
        margin: "20px 0px",
      },
  },
  flexBox: {
    display: 'flex',
    alignItems: 'center',
  },
  office1: {
    width: '100%'
  },
  office2: {
    width: '100%'
  },
  imgRaised,
  rounded,
  imgFluid
});

export default servicesStyle;
